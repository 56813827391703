import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';

import { AppStore } from '@app/appstore.model';
import { User } from '@modules/user/user.model';
import {
  PLHttpService,
  PLToastService,
  PLApiContactTypesService,
  PLApiLanguagesService,
  PLApiUsStatesService,
  PLFormService,
} from '@root/index';

@Component({
  selector: 'pl-client-contact-save',
  templateUrl: './pl-client-contact-save.component.html',
  styleUrls: ['./pl-client-contact-save.component.less'],
})
export class PLClientContactSaveComponent implements OnInit, OnChanges {
  @Output() onSave = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();

  @Input() client: any = {};
  @Input() contact: any = {};
  @Input() contactTypes: any[] = [];
  @Input() languages: any[] = [];
  @Input() showToastOnSave = true;

  private readonly contactEmergencyContactValue = 'emergency_contact';
  private readonly contactLearningCoachValue = 'learning_coach';
  contactRelationship: any[] = [];
  contactRelationshipOpts: any[] = [
    { value: this.contactEmergencyContactValue, label: 'Emergency Contact' },
    { value: this.contactLearningCoachValue, label: 'Learning Coach' },
  ];

  user: User;
  selectOptsStates: any[] = [];

  contactSaveForm: UntypedFormGroup = new UntypedFormGroup({});
  savingContact: boolean = false;
  selectOptsRelationship: any[] = [];
  selectOptsLanguage: any[] = [];
  selectOptsContactPreference: any[] = [
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
  ];

  constructor(
    private plHttp: PLHttpService,
    private store: Store<AppStore>,
    private plToast: PLToastService,
    private plContactTypes: PLApiContactTypesService,
    private plLanguages: PLApiLanguagesService,
    private plStates: PLApiUsStatesService,
  ) {
    store.select('currentUser').subscribe(user => {
      this.user = user;
    });
  }

  getContactRelationship(): any[] {
    let arr = [];

    if (this.contact.is_emergency) {
      arr.push(this.contactEmergencyContactValue);
    }

    if (this.contact.is_responsible_party) {
      arr.push(this.contactLearningCoachValue);
    }

    return arr;
  }

  ngOnInit() {
    this.formLanguageOpts();
    this.formContactTypeOpts();
    this.formStatesOpts();
  }

  ngOnChanges(changes: any) {
    if (changes.languages) {
      this.formLanguageOpts();
    } else if (changes.contactTypes) {
      this.formContactTypeOpts();
    }

    this.contactRelationship = this.getContactRelationship();
  }

  formStatesOpts() {
    this.selectOptsStates = this.plStates.getOptsGQL();
  }

  formContactTypeOpts() {
    this.selectOptsRelationship = this.plContactTypes.formOpts(
      this.contactTypes,
    );
    if (this.contactTypes.length === 1) {
      this.contact.contact_type = this.contactTypes[0].uuid;
    }
  }

  formLanguageOpts() {
    this.selectOptsLanguage = this.plLanguages.formOpts();
  }

  private saveParams() {
    return Object.assign({}, this.contact, {
      client: this.client.id,
      is_emergency: this.contactRelationship.includes(
        this.contactEmergencyContactValue,
      ),
      is_responsible_party: this.contactRelationship.includes(
        this.contactLearningCoachValue,
      ),
    });
  }

  save(form: any) {
    PLFormService.markAllAsTouched(form);

    if (form.valid) {
      this.savingContact = true;

      this.plHttp.save('contacts', this.saveParams()).subscribe(
        (res: any) => {
          if (this.showToastOnSave) {
            this.plToast.show('success', 'Contact saved.', -1, true);
          }
          this.savingContact = false;
          form.reset();
          this.onSave.emit(res);
        },
        () => {
          this.savingContact = false;
        },
      );
    }
  }

  cancel() {
    this.onCancel.emit();
  }

  delete(form: any) {
    this.savingContact = true;
    const params = {
      uuid: this.contact.uuid,
    };
    this.plHttp.delete('contacts', params).subscribe(
      () => {
        if (this.showToastOnSave) {
          this.plToast.show('success', 'Contact removed.', 2000, true);
        }
        this.savingContact = false;
        form.reset();
        this.onDelete.emit();
      },
      () => {
        this.savingContact = false;
      },
    );
  }
}
