import {
  Component,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { debounce, uniq } from 'lodash';

import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  Subscription,
  throwError,
} from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { AppStore } from '@app/appstore.model';
import { DemandSpecialty } from '@common/assigment-machine/constants/demand-specialties.constants';
import { PLNote } from '@common/components/pl-notes-list/pl-notes-list.component';
import {
  BMH_THERAPY_FREQUENCY,
  REFERRAL_REASON,
  TRACKING_TYPE,
  TRACKING_TYPE_OPTIONS,
} from '@common/constants';

import {
  PLProviderTypeCode,
  PL_REFERRAL_STATE,
  PLClinicalProductCode,
} from '@common/enums';
import { FeatureFlagName, FeatureFlagsService } from '@common/feature-flags';
import { Option } from '@common/interfaces';
import { BMHTherapyFrequency } from '@common/interfaces/pl-referral';
import {
  PLProviderTypesService,
  PLReferralNotesService,
  PLSchoolYearsService,
  PLUtilService,
} from '@common/services/';

import {
  PLReferralGroupLabel,
  referralGroupingFromCheckboxOptionValues,
  referralGradeOptions,
  referralIntervalOptions,
  PL_PROVIDER_REFERRAL_MAP,
  ClinicalTalkFrequency,
  toClinicalTalkFrequency,
  isUpdatedClinicalTalkFrequency,
  referralGroupingCheckboxOptions,
  referralGroupingToCheckboxValues,
} from '@common/services/pl-client-referral';
import { selectCurrentUser, selectCurrentUserLoaded } from '@common/store';

import { PLClientReferralDeleteComponent } from '@modules/clients/pl-client-direct-referral/pl-client-referral-delete/pl-client-referral-delete.component';
import { User } from '@modules/user/user.model';
import {
  PLMayService,
  PLModalService,
  PLToastService,
  PLLodashService,
  PLGraphQLService,
  PLGQLQueriesService,
  PLApiLanguagesService,
  PLConfirmDialogService,
  PLTransformGraphQLService,
} from '@root/index';
import { Referral } from '../../add-referrals/pl-client-referral-data-model.service';
import { Demand } from '../../assignment-manager/pl-assignment-manager.model';
import { PLAssignmentManagerService } from '../../assignment-manager/pl-assignment-manager.service';
import { PLClientReferralSaveService } from '../pl-client-referral-save.service';

type SpecialtiesValues = DemandSpecialty | 'None';

@Component({
  selector: 'pl-client-referral-save-referral',
  templateUrl: './pl-client-referral-save-referral.component.html',
  styleUrls: ['./pl-client-referral-save-referral.component.less'],
})
export class PLClientReferralSaveReferralComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() saving = false;
  @Input() client: any = { primaryLanguage: {} };

  @Output() onSave = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @Output() onChangeClient = new EventEmitter<any>();
  @Output() onSaveAndConvert = new EventEmitter<any>();

  readonly allowedSpecialties: DemandSpecialty[] = [
    DemandSpecialty.aac,
    DemandSpecialty.asl,
    DemandSpecialty.dhh,
    DemandSpecialty.vi,
  ];

  defaultReferral: any = {
    isScheduled: false,
    schoolYear: { code: null },
    providerType: { code: PLProviderTypeCode.SLP },
    productType: { code: PLClinicalProductCode.DIR_SVC },
    specialty: null,
  };

  @Input() referral: any = { ...this.defaultReferral };

  private confirmedClinicalTalkFrequency: ClinicalTalkFrequency;

  readonly gradeOptions: Option[] = referralGradeOptions;
  readonly intervalOptions: Option[] = referralIntervalOptions;
  readonly referralGroupingCheckboxOptions = referralGroupingCheckboxOptions;

  currentUser: User;
  currentSchoolYearCode: string;

  displayNotesComponentForRSM = false;

  errorMessage = '';
  esy: string[] = [];
  isShortTerm: string[] = [];

  frequencyOpts: BMHTherapyFrequency[] = [
    BMH_THERAPY_FREQUENCY.TWICE_WEEKLY,
    BMH_THERAPY_FREQUENCY.ONCE_WEEKLY,
  ];
  frequencyTypeCode: string = null;

  grouping = {
    disabled: false,
    label: PLReferralGroupLabel.Individual,
  };

  isEdit = false;

  languagesOptsOther: any[] = [];
  languagesOptsOtherSecondary: any[] = [];

  onInitLoading = true;

  matchingOpts: any[] = [];

  providerTypeOptions: any[] = [];
  private providerTypeOptionCodes: string[];

  referralGrouping: string[] = [];

  referralForm: UntypedFormGroup = new UntypedFormGroup({});
  referralOpts: any[];
  rsmNoteAsPLNote: PLNote[] = [];

  schoolYearOpts: Option[] = [];
  selectedSchoolYear: string = null;

  newNote = { text: '', userMentions: [] };
  isNoteEditing = false;
  referralLoaded = false;
  subscriptions: Record<string, Subscription> = {};

  isRsmFieldDisplayed = false;

  trackingTypeOptions: Option[] = TRACKING_TYPE_OPTIONS;

  isDHEditable = false;

  dhConfirmationRadioOpts: any[] = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  dhConfirmationRadioModel = undefined;

  isCalculatingServices = false;

  dedicatedServicesEnabled$: Observable<boolean>;
  selectedSchoolYearCode$ = new BehaviorSubject<string>(null);

  specialtiesOpts: { label: string; value: SpecialtiesValues }[] = [];

  /**
   * For being used only in `ngOnChanges` method.
   * We shouldn't call some methods until `onInit` finishes.
   */
  private debounceNgOnChanges = debounce(changes => {
    if (this.onInitLoading) {
      this.debounceNgOnChanges(changes);
      return;
    }
    this.executeNgOnChanges(changes);
  }, 100);

  dedicatedService: boolean = false;
  hasSomeDirectServices: boolean = false;
  isMixedServiceType: boolean = false;
  isBMHDirectService: boolean = false;

  showGeneralEducationIndicator$: Observable<boolean>;

  constructor(
    private plMay: PLMayService,
    private store$: Store<AppStore>,
    private plModal: PLModalService,
    private plUtilSvc: PLUtilService,
    private plLodash: PLLodashService,
    private plToastSvc: PLToastService,
    private plGraphQL: PLGraphQLService,
    private plGQLQueries: PLGQLQueriesService,
    private plLanguages: PLApiLanguagesService,
    private yearsService: PLSchoolYearsService,
    private plProviderTypesSvc: PLProviderTypesService,
    private confirmDialogService: PLConfirmDialogService,
    private plTransformGraphQL: PLTransformGraphQLService,
    private pLReferralNotesService: PLReferralNotesService,
    private plAssignmentManagerService: PLAssignmentManagerService,
    private plClientReferralSaveSvc: PLClientReferralSaveService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.dedicatedServicesEnabled$ = combineLatest([
      this.selectedSchoolYearCode$,
    ]).pipe(
      map(([schoolYearCode]) => {
        return this.isFY24(schoolYearCode);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.onInitLoading) {
      this.debounceNgOnChanges(changes);
      return;
    }
    this.executeNgOnChanges(changes);
  }

  ngOnInit(): void {
    this.showGeneralEducationIndicator$ =
      this.featureFlagsService.isFeatureEnabled(
        FeatureFlagName.showGeneralEducationIndicator,
      );

    const currentUser$ = this.store$.select(selectCurrentUser).pipe(first());
    const schoolYearOptions$ = this.yearsService.getYearsData().pipe(first());
    const currentSchoolYear$ = this.yearsService
      .getCurrentSchoolYear()
      .pipe(first());

    this.subscriptions.userAndYears = this.store$
      .select(selectCurrentUserLoaded)
      .pipe(
        filter(userLoaded => userLoaded),
        switchMap(_ =>
          forkJoin([currentUser$, currentSchoolYear$, schoolYearOptions$]),
        ),
      )
      .subscribe(([user, currentSchoolYear]) => {
        this.currentUser = user;

        this.formClient(this.client);
        this.formLanguageOpts();
        this.formProviderTypeOptions();
        this.formMatchingOpts();

        this.currentSchoolYearCode = currentSchoolYear.code;
        this.schoolYearOpts = this.yearsService
          .getYearOptions()
          .slice(-3)
          .reverse();
        this.onInitLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.userAndYears?.unsubscribe();
    this.subscriptions.assignmentsAndDemands?.unsubscribe();
  }

  private isFY24(schoolYearCode: string) {
    return schoolYearCode === '2023-2024-regular';
  }

  shouldDisableProviderTypes(): boolean {
    return (
      this.referral.state === PL_REFERRAL_STATE.Matched &&
      (this.plMay.isCustomerAdmin(this.currentUser) ||
        this.plMay.isClinicalAccountManager(this.currentUser))
    );
  }

  /**
   * Activates a flag for displaying the RSM notes.
   *
   * Maps the `referral` object to a `PLNote` object, since this is needed
   * for displaying the data correctly in the `pl-notes-list` component.
   *
   * Call this method when we're sure the notes for RSM have to be displayed.
   */
  displayNotesForRSM(referral: any): void {
    this.displayNotesComponentForRSM = true;

    this.rsmNoteAsPLNote =
      this.pLReferralNotesService.createRsmReferralNote(referral);
  }

  formClient(client1: any = {}) {
    let client = this.plTransformGraphQL.fromSnakeCase(client1);
    client = Object.assign({}, { primaryLanguage: {} }, client);
    client.xBirthday = client.birthday
      ? dayjs(client.birthday, 'YYYY-MM-DD').format('MM/DD/YYYY')
      : '';

    if (!client.primaryLanguage) {
      client.primaryLanguage = {};
    }
    if (client.locations?.length) {
      client.location = client.locations[0].id;
    }

    this.client = client;
  }

  formLanguageOpts() {
    this.plLanguages.get().subscribe((resLanguages: any) => {
      const primaryOpts = this.plLanguages.formSelectOpts(resLanguages, [
        'en',
        'es',
      ]);
      const topOpts: any[] = [
        { value: 'en', label: 'English' },
        { value: 'es', label: 'Spanish' },
        { value: '', label: '--------------------' },
      ];
      this.languagesOptsOther = topOpts.concat(primaryOpts);

      const secondaryOpts = this.plLanguages.formSelectOpts(resLanguages, [
        'en',
        'es',
      ]);
      this.languagesOptsOtherSecondary = topOpts.concat(secondaryOpts);
    });
  }

  getReferralSpecialty(referral: Referral): SpecialtiesValues {
    if (referral.isAac) {
      return DemandSpecialty.aac;
    }
    if (referral.isVi) {
      return DemandSpecialty.vi;
    }
    if (referral.isAsl) {
      return DemandSpecialty.asl;
    }
    if (referral.isDhh) {
      return DemandSpecialty.dhh;
    }

    return 'None';
  }

  formReferral(referral: any = {}, referralChanges: any): void {
    this.isEdit = referral.id ? true : false;
    this.isRsmFieldDisplayed = this.isEdit && referral?.isRsmSchool;

    if (referral?.dueDate) {
      referral.dueDate = referral.dueDate.slice(0, 10);
    }

    if (this.isRsmFieldDisplayed && referral.notes) {
      this.displayNotesForRSM(referral);
    }

    if (!this.isEdit && this.currentUser?.xProvider) {
      referral.providerType = { ...this.currentUser.xProvider.providerType };
    }

    const schoolYearCode = !referralChanges.currentValue?.schoolYear
      ? this.currentSchoolYearCode // current school year; when new referral.
      : referralChanges.currentValue.schoolYear.code; // existing school year; when editing referral.

    this.referral = {
      ...this.defaultReferral,
      ...referral,
      schoolYear: {
        code: schoolYearCode,
      },
      language: {
        code: referral?.language?.code ? referral.language.code : 'en',
      },
      trackingType: referral?.trackingType || TRACKING_TYPE.REGULAR,
      isDedicated: (() => {
        if (referral?.isDedicated !== undefined) {
          return referral?.isDedicated ? 'Yes' : 'No';
        }
        return undefined;
      })(),
      specialty: referral ? this.getReferralSpecialty(referral) : 'None',
    };
    this.dhConfirmationRadioModel = this.referral.isDedicated;
    this.esy = this.referral.esy ? ['esy'] : [];
    this.isShortTerm = this.referral.isShortTerm ? ['isShortTerm'] : [];
    this.referralGrouping = referralGroupingToCheckboxValues(
      this.referral.grouping,
    );
    this.confirmedClinicalTalkFrequency = this.referral.isScheduled
      ? toClinicalTalkFrequency(this.referral)
      : null;
    this.referralLoaded = true;

    this.handleReferralSchoolYearChange(schoolYearCode);
  }

  /**
   * Build the `Provider Type` options that the user can see/select.
   */
  formProviderTypeOptions(): void {
    this.plProviderTypesSvc.formProviderTypeOptions();
    this.plProviderTypesSvc.providerTypesSubject
      .pipe(
        filter((options: any) => options.length),
        first(),
        tap(_ => this.setProviderTypeOptionsBasedOnUserGroups()),
      )
      .subscribe(
        (providerTypeOptions: Option[]) => {
          if (this.shouldDisableProviderTypes()) {
            providerTypeOptions.forEach(
              (element: any) => (element.disabled = true),
            );
          } else if (this.plMay.isProvider(this.currentUser)) {
            let cbForFilteringProviderTypeOptions = (element: {
              label: string;
              value: string;
            }) => {
              if (element.value === this.providerTypeOptionCodes[0]) {
                return element;
              }
            };

            if (this.providerTypeOptionCodes.length > 1) {
              cbForFilteringProviderTypeOptions = (element: {
                label: string;
                value: string;
              }) => {
                for (const iterator of this.providerTypeOptionCodes) {
                  if (element.value === iterator) {
                    return element;
                  }
                }
              };
            }

            providerTypeOptions = providerTypeOptions.filter(
              cbForFilteringProviderTypeOptions,
            );
          }

          this.providerTypeOptions = providerTypeOptions;
        },
        error =>
          console.error("Error: Building the 'Provider Type' options\n", error),
      );
  }

  formMatchingOpts() {
    if (this.currentUser.xGlobalPermissions) {
      const matchingOpts: any[] = [];
      const newClient = !this.client.id ? true : false;

      const isLead = this.plMay.isLead(this.currentUser);
      const isProvider = this.plMay.isProvider(this.currentUser);
      const mayProvideServices =
        this.currentUser.xGlobalPermissions.provideServices;
      const adminOrCustomer =
        this.plMay.isAdminType(this.currentUser) ||
        this.plMay.isCustomerAdmin(this.currentUser);
      // Reset.
      this.referral.matching = '';

      if (!this.isEdit) {
        this.referral.provider = '';
      }
      if (this.isEdit) {
        if (this.referral.provider && mayProvideServices) {
          this.referral.matching = 'selfAssign';
        } else if (
          this.referral.state &&
          this.referral.state === 'UNMATCHED_PL_REVIEW'
        ) {
          this.referral.matching = 'match';
        } else {
          this.referral.matching = 'match';
        }
      }
      if (mayProvideServices) {
        matchingOpts.push({ value: 'selfAssign', label: 'Assign to self' });
        if (!this.isEdit) {
          this.referral.matching = 'selfAssign';
          this.referral.provider = this.currentUser.uuid;
        }
      } else if (this.isMixedServiceType && isProvider && !this.isEdit) {
        this.matchingOpts = [{ value: 'selfAssign', label: 'Assign to self' }];
        this.referral.matching = 'selfAssign';
        this.referral.provider = this.currentUser.uuid;
      }
      if ((!newClient && adminOrCustomer) || isLead) {
        if (!this.isEdit && !isLead) {
          this.referral.matching = 'match';
        }
      }
      if (
        (isProvider && !this.isMixedServiceType) ||
        (!newClient && adminOrCustomer) ||
        isLead
      ) {
        matchingOpts.push({
          value: 'match',
          label: 'Send to Presence for matching',
        });
      }
      if (!this.isEdit && !matchingOpts.length) {
        matchingOpts.push({
          value: 'match',
          label: 'Send to Presence for matching',
        });
        this.referral.matching = 'match';
      }

      this.matchingOpts = matchingOpts;
    }
  }

  isDirectServiceOrSupervision(): boolean {
    const code = this.referral?.productType?.code;
    return (
      code === PLClinicalProductCode.DIR_SVC ||
      code === PLClinicalProductCode.SV
    );
  }

  isEvaluation(): boolean {
    return this.referral?.productType?.code === PLClinicalProductCode.EVAL;
  }

  /**
   * In charge of displaying certain HTML sections of this component
   */
  isBehaviorOrTraumaGroup(): boolean {
    let isBehaviorOrTrauma = false;

    if (this.referral.productType?.code) {
      isBehaviorOrTrauma =
        this.referral.productType.code === PLClinicalProductCode.TG ||
        this.referral.productType.code === PLClinicalProductCode.BIG;
    }

    return isBehaviorOrTrauma;
  }

  /**
   * TODO: same logic exists at `PLServiceSaveIdentify.updateBmhRadioButtonSelected` consider merging.
   * Updates the selected radio button of the Behavioral product frequency.
   * This scenario applies only when editing a referral
   */
  loadForEditReferralWhenBmhType(): void {
    let freqTypeCode = null;

    if (this.referral.duration) {
      freqTypeCode =
        this.referral.duration === 30
          ? BMH_THERAPY_FREQUENCY.TWICE_WEEKLY.value
          : BMH_THERAPY_FREQUENCY.ONCE_WEEKLY.value;
      this.frequencyTypeCode = freqTypeCode; // Select the radio button in the UI
    }

    this.updateFrequencyWhenBmhType(freqTypeCode);
  }

  onChangeGrouping(checkedValues: string[]) {
    this.referral.grouping =
      referralGroupingFromCheckboxOptionValues(checkedValues);
  }

  onChangeEsy(checkedValues: string[]) {
    this.referral.esy = checkedValues.includes('esy');
  }

  onChangeDH(checkedValue: boolean) {
    this.referral.isDedicated = checkedValue;
  }

  onChangeGeneralEducation(checkedValue: boolean) {
    this.referral.generalEducation = checkedValue;
  }

  onChangeIsShortTerm(checkedValues: string[]) {
    this.referral.isShortTerm = checkedValues.includes('isShortTerm');
  }

  changeClient() {
    this.onChangeClient.emit();
  }

  validate() {
    if (this.referralForm.valid) {
      // NOTE: On every new/edited referral,
      // we are explicitly setting the referral isFte because
      // starting in FY24, we are moving away from FTE products
      // in favor of dedicated service model.
      // Based on querying production data in Aug 2023,
      // total amount of referrals that would be altered by this
      // line are small in number for cases where isFte=False
      // and larger (but expected) for cases where isFte=True - thus,
      // this line performs a gradual cleanup of the isFte=True cases over time.
      this.referral.isFte = null;

      // If we have a string value...
      if (
        typeof this.referral.isDedicated === 'string' ||
        this.referral.isDedicated instanceof String
      ) {
        if (this.referral.isDedicated === 'No') {
          this.referral.isDedicated = false;
        } else if (this.referral.isDedicated === 'Yes') {
          this.referral.isDedicated = true;
        }
      }

      return true;
    }
    return false;
  }

  hasValidNotes() {
    if (!this.isNoteEditing || !this.referral.id) {
      return true;
    }

    this.confirmDialogService.show({
      header: 'Notes being edited',
      content:
        'There are notes that have not being saved yet. Save or cancel notes editing to proceed',
      primaryLabel: 'Close',
      primaryCallback: () => {},
    });
    return false;
  }

  save() {
    if (!this.hasValidNotes()) {
      return;
    }
    if (this.validate()) {
      this.confirmClinicalTalkFrequency(() =>
        this.onSave.emit({
          client: this.client,
          referral: this.referral,
          note: this.newNote,
        }),
      );
    } else {
      this.errorMessage = 'Please fill out all required fields.';
    }
  }

  saveAndConvert() {
    if (!this.hasValidNotes()) {
      return;
    }
    if (this.validate()) {
      this.confirmClinicalTalkFrequency(() =>
        this.onSaveAndConvert.emit({
          client: this.client,
          referral: this.referral,
          note: this.newNote,
        }),
      );
    } else {
      this.errorMessage = 'Please fill out all required fields.';
    }
  }

  cancel() {
    this.onCancel.emit();
  }

  showDeleteConfirm() {
    let modalRef: any;
    const params: any = {
      referral: Object.assign(
        { reason: REFERRAL_REASON.DUPLICATE_REFERRAL },
        this.referral,
      ),
      onDelete: (referral: { reason: string }) => {
        const variables: any = {
          deleteReferralInput: {
            id: this.referral.id,
            reason: referral.reason,
          },
        };
        const moreParams: any = {
          updateQueries: {
            ClientReferrals: (prev: any) => {
              const newReferrals: any = prev.referrals;
              const removeIndex = this.plLodash.findIndex(
                newReferrals.edges,
                'node.id',
                this.referral.id,
              );
              if (removeIndex > -1) {
                newReferrals.edges.splice(removeIndex);
              }
              return {
                referrals: newReferrals,
              };
            },
          },
          refetchQueries: this.plGQLQueries.queryGroups.referralsAndServices,
        };
        this.plGraphQL
          .mutate(
            `mutation deleteReferral($deleteReferralInput: DeleteReferralInput!) {
                    deleteReferral(input: $deleteReferralInput) {
                        errors {
                            code
                            field
                            message
                        }
                        status
                    }
                }`,
            variables,
            {},
            moreParams,
          )
          .subscribe(() => {
            modalRef.instance.destroy();
            this.plToastSvc.show('success', `Referral Deleted`, 1500, true);
            const that = this;
            setTimeout(() => {
              that.cancel();
            }, 2000);
          });
      },
      onCancel: () => {
        modalRef.instance.destroy();
      },
    };

    this.plModal
      .create(PLClientReferralDeleteComponent, params)
      .subscribe((ref: any) => {
        modalRef = ref;
      });
  }

  /**
   * Handles a `Provider Type` change by updating the `Referral` options:
   *  - Update the 'Referral' options to display.
   *  - Update the default selected option.
   *
   * This takes into account the provider, its referral options, and the previous selected option.
   *
   * @param providerTypeCode The code that identifies a provider.
   */
  handleProviderTypeChange(providerTypeCode: string): void {
    let hasInvalidReferralPreselected: boolean;
    let referralTypeCode = this.referral.productType.code;

    this.referralOpts = PL_PROVIDER_REFERRAL_MAP[providerTypeCode]; // Display the options

    switch (providerTypeCode) {
      case PLProviderTypeCode.OT:
        referralTypeCode =
          this.plClientReferralSaveSvc.getReferralToSelectWhenOtOrSLP(
            referralTypeCode,
          );
        break;

      case PLProviderTypeCode.PA:
        if (referralTypeCode === PLClinicalProductCode.SV) {
          this.updateFrequencyWhenBmhType(null); // Resetting frequency
        }
        referralTypeCode =
          this.plClientReferralSaveSvc.getReferralToSelectWhenMHP(
            referralTypeCode,
          );
        break;

      case PLProviderTypeCode.PT:
        hasInvalidReferralPreselected =
          referralTypeCode !== PLClinicalProductCode.EVAL &&
          referralTypeCode !== PLClinicalProductCode.DIR_SVC;

        if (hasInvalidReferralPreselected) {
          referralTypeCode = PLClinicalProductCode.DIR_SVC;
        }
        break;

      case PLProviderTypeCode.RS:
        referralTypeCode = PLClinicalProductCode.GROUP_READING;
        break;

      case PLProviderTypeCode.APE:
        referralTypeCode = PLClinicalProductCode.DIR_SVC;
        break;

      case PLProviderTypeCode.MHP:
        if (referralTypeCode === PLClinicalProductCode.SV) {
          this.updateFrequencyWhenBmhType(null); // Resetting frequency
        }
        referralTypeCode =
          this.plClientReferralSaveSvc.getReferralToSelectWhenMHP(
            referralTypeCode,
          );
        break;

      case PLProviderTypeCode.SLP:
        referralTypeCode =
          this.plClientReferralSaveSvc.getReferralToSelectWhenOtOrSLP(
            referralTypeCode,
          );
        break;

      case PLProviderTypeCode.BCBA:
        hasInvalidReferralPreselected =
          referralTypeCode !== PLClinicalProductCode.SV &&
          referralTypeCode !== PLClinicalProductCode.DIR_SVC;

        if (hasInvalidReferralPreselected) {
          referralTypeCode = PLClinicalProductCode.DIR_SVC;
        }
        break;

      case PLProviderTypeCode.EDDIAG:
        referralTypeCode = PLClinicalProductCode.EVAL;
        break;

      default:
        console.error(
          'Error:',
          `\nThere was an err when selecting a referral from the options list.\nThe provider received is: ${providerTypeCode}`,
        );
    }

    this.referral.productType.code = referralTypeCode;
    this.updateBMHDirectService();
  }

  updateBMHDirectService(): void {
    const providerTypeCode = this.referral.providerType?.code;
    const BMHServiceProviderTypes = [
      PLProviderTypeCode.MHP,
      PLProviderTypeCode.PA,
    ];
    this.isBMHDirectService =
      BMHServiceProviderTypes.includes(providerTypeCode) &&
      this.referral.productType?.code == PLClinicalProductCode.DIR_SVC;

    if (!this.isBMHDirectService) {
      this.referral.generalEducation = false;
    }
  }

  /**
   * Behavioral product displays only two fixed frequencies instead of three select boxes with different combinations
   * Therefore:
   *   Updating manually the referral object.
   *   Update made based on the fixed options.
   */
  updateFrequencyWhenBmhType(freqTypeCode: string): void {
    if (freqTypeCode) {
      const twiceWeekly = { duration: 30, frequency: 2 };
      const onceWeekly = { duration: 60, frequency: 1 };
      const frequency: any =
        freqTypeCode === BMH_THERAPY_FREQUENCY.TWICE_WEEKLY.value
          ? twiceWeekly
          : onceWeekly;

      this.referral.interval = 'weekly';
      this.referral.duration = frequency.duration;
      this.referral.frequency = frequency.frequency;
    } else {
      this.frequencyTypeCode = null;
      delete this.referral.duration;
      delete this.referral.frequency;
      delete this.referral.interval;
    }
  }

  /**
   * If the user selects Behavior, Trauma, or Eval some updates must happen:
   * - Updating of title labels
   * - Selection of specific checkboxes
   * - Updating frequencies of the Referral.
   * - Reset some fields that are not relevant to Evals
   */
  handleProductReferralChange(referralTypeCode: string): void {
    this.updateFrequencyWhenBmhType(this.frequencyTypeCode);
    this.updateBMHDirectService();
  }

  onNoteEditing(isNoteEditing: boolean) {
    this.isNoteEditing = isNoteEditing;
  }

  onNoteChange(event: any) {
    this.newNote = event;
  }

  onChangeSchoolYear({ model: schoolYearCode }): void {
    this.handleReferralSchoolYearChange(schoolYearCode);
  }

  onChangeDHRadio(value: string): void {
    this.referral.isDedicated = value === 'Yes' ? true : false;
  }

  get isDedicatedServicesDisabled() {
    return !this.isDHEditable || this.isRsmFieldDisplayed;
  }

  private confirmClinicalTalkFrequency(onConfirm: () => void) {
    const clinicalTalkFrequency = toClinicalTalkFrequency(this.referral);
    const isConfirmationRequired =
      this.confirmedClinicalTalkFrequency &&
      isUpdatedClinicalTalkFrequency(
        [this.confirmedClinicalTalkFrequency],
        clinicalTalkFrequency,
      );

    if (isConfirmationRequired) {
      this.confirmDialogService.show({
        header: 'Changing Scheduling Information',
        content: `You are changing information that affects scheduling. Do you want to proceed with this change?`,
        primaryLabel: 'Yes',
        secondaryLabel: 'No',
        primaryCallback: onConfirm,
      });
    } else {
      onConfirm();
    }
  }

  /**
   * Wraps the logic that used to live in `ngOnChanges` method.
   * Should be called only from `ngOnChanges` or `debounceNgOnChanges`.
   * The logic here must be called until `this.currentUser` is defined.
   *
   * @param changes The changes argument gotten from `ngOnChanges` method.
   */
  private executeNgOnChanges(changes: SimpleChanges): void {
    if (changes.client) {
      this.formMatchingOpts();
      this.formClient(this.client);
    }
    if (changes.referral) {
      this.formReferral(this.referral, changes.referral);
      this.formMatchingOpts();

      this.handleProviderTypeChange(this.referral.providerType.code);
      if (this.isBehaviorOrTraumaGroup()) {
        this.loadForEditReferralWhenBmhType();
      }
    }
  }

  /**
   * Checks the provider type that the user belongs to based on its `groups`.
   * An array with the provider type codes that the user belongs to is created.
   */
  private setProviderTypeOptionsBasedOnUserGroups(): void {
    if (this.plMay.isProvider(this.currentUser)) {
      const providerTypeOptionCodes =
        this.plProviderTypesSvc.getProviderTypeFromUser(this.currentUser);

      if (!providerTypeOptionCodes.length) {
        console.error(
          'Error:',
          this.plProviderTypesSvc.ERR_MSG.mapUserGroupWithProviderType,
        );
        this.plUtilSvc.errorLog(
          this.plProviderTypesSvc.ERR_MSG.mapUserGroupWithProviderType,
          {
            userGroups: this.currentUser.groups,
            providerTypesAvailable: PL_PROVIDER_REFERRAL_MAP,
          },
        );
        this.plToastSvc.show(
          'error',
          this.plProviderTypesSvc.ERR_MSG.nonExistentProviderType,
        );
        throw throwError(null);
      }

      this.providerTypeOptionCodes = providerTypeOptionCodes;
      this.defaultReferral.providerType.code =
        this.providerTypeOptionCodes && this.providerTypeOptionCodes[0]
          ? this.providerTypeOptionCodes[0]
          : 'slp';
    }
  }

  getSpecialtiesFromDemands(demands: Demand[]) {
    if (!demands || demands.length === 0) return [];

    const specialties = demands.reduce((acc, demand) => {
      const s: DemandSpecialty[] = [];

      if (demand.is_aac) {
        s.push(DemandSpecialty.aac);
      }
      if (demand.is_dhh) {
        s.push(DemandSpecialty.dhh);
      }
      if (demand.is_asl) {
        s.push(DemandSpecialty.asl);
      }
      if (demand.is_vi) {
        s.push(DemandSpecialty.vi);
      }

      return [...acc, ...s];
    }, []);

    return [...new Set(specialties)];
  }

  /**
   * For the given school year, use the organization service model information
   * to automatically set the referral dedicated value - if we can't determine,
   * we do let the user set manually.
   *
   * @param schoolYearCode
   */
  private handleReferralSchoolYearChange(schoolYearCode: string): void {
    if (this.referral.isRsmSchool) return;

    this.selectedSchoolYearCode$.next(schoolYearCode);
    this.isCalculatingServices = true;
    const { id: schoolYearId } =
      this.yearsService.getYearForCode(schoolYearCode);
    const organizationId = this.client.locations[0].rateHolder.id;

    this.subscriptions.assignmentsAndDemands = this.plAssignmentManagerService
      .getOrganizationServiceModel(schoolYearId, organizationId)
      .subscribe(
        ({ demand_service_models, demands_info }) => {
          this.dedicatedService =
            demand_service_models.length === 1 &&
            demand_service_models[0] === 'DH';

          this.setIsDHCalculatedValue(this.dedicatedService);
          const _demands: Demand[] = demands_info.reduce(
            (acc, item) => [...acc, ...item.demands],
            [],
          );

          this.hasSomeDirectServices =
            !this.dedicatedService && _demands.some(item => item.is_dh);

          const specialties = this.getSpecialtiesFromDemands(_demands);
          this.setSpecialtiesOpts(specialties);

          this.isMixedServiceType =
            uniq(_demands.map(({ service_model }) => service_model)).length > 1;
          this.isCalculatingServices = false;
        },
        (error: any) => {
          console.error(error);
          this.isCalculatingServices = false;
        },
      );
  }

  private setIsDHCalculatedValue(dedicated: boolean): void {
    const isConfirmationNeeded = dedicated === this.isMixedServiceType;

    // Only set the value if the referral is not already set as dedicated
    if (!this.referral.isDedicated) {
      if (!isConfirmationNeeded) {
        this.referral.isDedicated = dedicated;
      } else if (isConfirmationNeeded) {
        this.dhConfirmationRadioModel = undefined;
        this.referral.isDedicated = false;
      }
    }

    this.isDHEditable = this.isMixedServiceType;
    this.formMatchingOpts();
  }

  private setSpecialtiesOpts(specialties: DemandSpecialty[]): void {
    const availableSpecialties = specialties.filter(specialty =>
      this.allowedSpecialties.includes(specialty),
    );

    if (
      this.referral.isAac &&
      !availableSpecialties.includes(DemandSpecialty.aac)
    ) {
      availableSpecialties.push(DemandSpecialty.aac);
    }

    if (
      this.referral.isAsl &&
      !availableSpecialties.includes(DemandSpecialty.asl)
    ) {
      availableSpecialties.push(DemandSpecialty.asl);
    }

    if (
      this.referral.isVi &&
      !availableSpecialties.includes(DemandSpecialty.vi)
    ) {
      availableSpecialties.push(DemandSpecialty.vi);
    }

    if (
      this.referral.isDhh &&
      !availableSpecialties.includes(DemandSpecialty.dhh)
    ) {
      availableSpecialties.push(DemandSpecialty.dhh);
    }

    this.specialtiesOpts = [
      { label: 'None', value: 'None' },
      ...availableSpecialties.map(specialty => ({
        label: specialty,
        value: specialty,
      })),
    ];
  }
}
