import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { Option } from '@common/interfaces';
import { PLUtilService } from '@common/services';

import {
  PLAssignmentProposalItem,
  PLAssignmentStatusEnum,
  StatusUpdateResults,
  ProviderDeclinedReasonsEnum,
} from './pl-assignment-manager.model';
import { PLAssignmentManagerService } from './pl-assignment-manager.service';
import { PLAssignmentProposalItemService } from './pl-assignment-proposal-item.service';
@Component({
  selector: 'pl-decline-assignments-modal',
  templateUrl: './pl-decline-assignments-modal.component.html',
  styleUrls: ['./pl-decline-assignments-modal.component.less'],
})
export class PLDeclineAssignmentModalComponent implements OnInit {
  @Input() camProposal: PLAssignmentProposalItem;
  @Input() providerAssignment: any;
  @Input() orgDemandList: any[];
  @Input() declinedReasonsOpts: Option[];
  @Input() onCancel: Function;
  @Input() onSaveSuccess: Function;
  @Input() onSaveError: Function;
  declineAssignmentsFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  inFlight = false;

  model: { reason: string; notes: string } = {
    reason: undefined,
    notes: undefined,
  };

  constructor(
    public util: PLUtilService,
    private service: PLAssignmentManagerService,
    private proposalItemService: PLAssignmentProposalItemService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    // this.headerText = 'Decline assignment';
    // this.declineDeclineVerb = 'declining';
    this.declineAssignmentsFormGroup = this.fb.group({
      reason: ['', Validators.required],
      notes: [{ value: '', disabled: true }],
    });

    this.declineAssignmentsFormGroup
      .get('reason')
      .valueChanges.subscribe(value => {
        const notesControl = this.declineAssignmentsFormGroup.get('notes');
        if (value === ProviderDeclinedReasonsEnum.OTHER) {
          notesControl.enable();
        } else {
          notesControl.disable();
        }
      });
  }

  onClickCancel() {
    this.onCancel();
  }

  onClickDecline() {
    const formValue = this.declineAssignmentsFormGroup.value;

    if (this.camProposal) {
      const notes = {
        pl_rejected_other_reason:
          formValue.reason === ProviderDeclinedReasonsEnum.OTHER &&
          formValue.notes,
      };
      const reason = {
        pl_rejected_reason: formValue.reason,
        ...(notes.pl_rejected_other_reason && notes),
      };
      const msg = 'decline cam proposal';
      this.inFlight = true;
      this.proposalItemService
        .rejectSingleProposal(this.orgDemandList, this.camProposal, reason)
        .pipe(first())
        .subscribe(
          (res: StatusUpdateResults) => {
            this.inFlight = false;
            this.util.log(`${msg} SUCCESS`, { res, STATE: this });
            if (res.saved.length) {
              this.onSaveSuccess(res.saved[0]);
            } else {
              this.onSaveError(res.failed[0]);
            }
          },
          (err: any) => {
            this.inFlight = false;
            this.onSaveError(err);
          },
        );
    } else {
      const notes = {
        provider_declined_other_reason:
          formValue.reason === ProviderDeclinedReasonsEnum.OTHER &&
          formValue.notes,
      };
      const reason = {
        provider_declined_reason: formValue.reason,
        ...(notes.provider_declined_other_reason && notes),
      };
      const msg = 'decline provider assignment';
      this.proposalItemService
        .updateProposalStatus(
          this.providerAssignment.uuid,
          PLAssignmentStatusEnum.PROVIDER_REJECTED,
          reason,
        )
        .pipe(first())
        .subscribe(
          (res: any) => {
            this.util.log(`${msg} SUCCESS`, { res, STATE: this });
            this.onSaveSuccess(res);
          },
          (err: any) => {
            this.util.errorLog(`${msg} ERROR`, { err, STATE: this });
            this.onSaveError(err);
          },
        );
    }
  }

  getNotesChars() {
    const notes = this.declineAssignmentsFormGroup.get('notes').value;
    return this.service.getCharsLength(notes) || 0;
  }

  isReasonOther() {
    return (
      this.declineAssignmentsFormGroup.get('reason').value ===
      ProviderDeclinedReasonsEnum.OTHER
    );
  }
}
