import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Option } from '@common/interfaces';
import { PLFormService } from '@root/index';

export interface ProposeMatchesEvent {
  organizationId: string;
}

@Component({
  selector: 'pl-client-referrals-propose-matches',
  templateUrl: './pl-client-referrals-propose-matches.component.html',
  styleUrls: ['./pl-client-referrals-propose-matches.component.less'],
})
export class PLClientReferralsProposeMatchesComponent {
  @Input() schoolYearLabel = '';
  @Input() organizationOptions: Option[] = [];

  @Output() readonly proposeMatches: EventEmitter<ProposeMatchesEvent> =
    new EventEmitter();
  @Output() readonly cancel: EventEmitter<any> = new EventEmitter();

  organizationId = '';

  readonly form: UntypedFormGroup = new UntypedFormGroup({});

  handleCancelClick(): void {
    this.cancel.emit();
  }

  handleProposeMatchesClick(): void {
    PLFormService.markAllAsTouched(this.form);

    if (this.form.valid) {
      const event = {
        organizationId: this.organizationId,
      };

      this.proposeMatches.emit(event);
    }
  }
}
