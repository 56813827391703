import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Option } from '@root/src/app/common/interfaces';
import { PLAssignedLocationsService } from '@root/src/app/common/services';
import { PLFormService } from '@root/src/lib-components';

import {
  OnboardingStepEmailData,
  OnboardingStepsEmailResponseData,
} from '../pl-customer-onboarding.types';

@Component({
  selector: 'pl-psp-training-modal',
  templateUrl: 'pl-psp-training-modal.component.html',
  styleUrls: ['pl-psp-training-modal.component.less'],
})
export class PLPSPTrainingModalComponent implements OnInit {
  @Input() sendMailFn: (
    data: OnboardingStepEmailData,
  ) => Observable<OnboardingStepsEmailResponseData>;
  @Input() stepId: string;

  pspTrainingForm: UntypedFormGroup = new UntypedFormGroup({});
  pspTrainingFormModel: {
    email: string;
    sendCopy: boolean;
  } = {
    email: '',
    sendCopy: false,
  };
  locationOptions: Option[];

  constructor(
    private locationsService: PLAssignedLocationsService,
    private toastr: ToastrService,
  ) {
    this.pspTrainingFormModel = {
      sendCopy: false,
      email: '',
    };
  }

  ngOnInit() {
    this.locationsService.getAllLocationsOnceAsMapping().subscribe(res => {
      this.locationOptions = res.getLocationOptions();
    });
  }

  showError() {
    this.toastr.error(
      'Could not send the PSP Training email. Please try again in a few moments.',
      'Error Sending Email',
      {
        positionClass: 'toast-bottom-right',
      },
    );
  }

  sendEmail(form: UntypedFormGroup) {
    PLFormService.markAllAsTouched(form);
    if (form.valid) {
      this.sendMailFn({
        onboardingStepId: this.stepId,
        email: this.pspTrainingFormModel.email,
        copyAdminUser: this.pspTrainingFormModel.sendCopy,
      }).subscribe(
        (res: OnboardingStepsEmailResponseData) => {
          if (!res.errors) {
            this.toastr.success(
              'PSP Training email successfully sent!',
              'Email Sent',
              {
                positionClass: 'toast-bottom-right',
              },
            );
            this.pspTrainingFormModel.email = null;
            this.pspTrainingFormModel.sendCopy = null;
            form.reset();
          } else {
            this.showError();
          }
        },
        () => {
          this.showError();
        },
      );
    }
  }
}
