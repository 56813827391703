import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Option } from '@root/src/app/common/interfaces';
import {
  PLAssignedLocationsService,
  PLLocationsOrgsMapping,
} from '@root/src/app/common/services';
import { PLFormService } from '@root/src/lib-components';

import {
  OnboardingStepEmailData,
  OnboardingStepsEmailResponseData,
} from '../pl-customer-onboarding.types';

@Component({
  selector: 'pl-tech-check-modal',
  templateUrl: 'pl-tech-check-modal.component.html',
  styleUrls: ['pl-tech-check-modal.component.less'],
})
export class PLTechCheckModalComponent implements OnInit {
  @Input() sendMailFn: (
    data: OnboardingStepEmailData,
  ) => Observable<OnboardingStepsEmailResponseData>;
  @Input() stepId: string;

  techCheckForm: UntypedFormGroup = new UntypedFormGroup({});
  techCheckFormModel: {
    location?: string;
    email?: string;
    sendCopy?: boolean;
  } = {};
  locationOptions: Option[];

  constructor(
    private locationsService: PLAssignedLocationsService,
    private toastr: ToastrService,
  ) {
    this.techCheckFormModel = {
      location: '',
      email: '',
      sendCopy: false,
    };
  }

  ngOnInit() {
    this.locationsService
      .getAllLocationsOnceAsMapping()
      .subscribe((res: PLLocationsOrgsMapping) => {
        this.locationOptions = res.getLocationOptions();
      });
  }

  showError() {
    this.toastr.error(
      'Could not send the Tech Check email. Please try again in a few moments.',
      'Error Sending Email',
      {
        positionClass: 'toast-bottom-right',
      },
    );
  }

  sendEmail(form: UntypedFormGroup) {
    PLFormService.markAllAsTouched(form);
    if (form.valid) {
      this.sendMailFn({
        onboardingStepId: this.stepId,
        email: this.techCheckFormModel.email,
        copyAdminUser: this.techCheckFormModel.sendCopy,
        locationId: this.techCheckFormModel.location,
      }).subscribe(
        (res: OnboardingStepsEmailResponseData) => {
          if (!res.errors) {
            this.toastr.success(
              'Tech Check email successfully sent!',
              'Email Sent',
              {
                positionClass: 'toast-bottom-right',
              },
            );
            this.techCheckFormModel.email = null;
            this.techCheckFormModel.location = null;
            this.techCheckFormModel.sendCopy = null;
            form.reset();
          } else {
            this.showError();
          }
        },
        () => {
          this.showError();
        },
      );
    }
  }
}
